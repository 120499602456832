import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import AppContext from '../../AppContext';
import HeaderConnected from '../HeaderConnected/HeaderConnected';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Menu from '../Menu/Menu';
import './LayoutConnected.scss';

export default function LayoutConnected({ children }) {
  const { menu, isConnected } = useContext(AppContext);

  if (isConnected === 'loading') {
    return (
      <div className="LayoutConnected">
        <div className="LayoutConnected__header">
          <Header />
        </div>
        <div className="LayoutConnected__content" />
        <div>
          <Footer />
        </div>
      </div>
    );
  }

  if (isConnected === 'disconnected') {
    return (
      <div className="LayoutConnected">
        <div className="LayoutConnected__header">
          <Header />
        </div>
        <div className="LayoutConnected__content">
          <p>
            Vous n&apos;êtes pas connecté, merci de vous identifier.{' '}
            <Link to="/login">Retour à la page Login &gt; </Link>
          </p>
        </div>
        <div>
          <Footer />
        </div>
      </div>
    );
  }

  return (
    <div className="LayoutConnected">
      <div className="LayoutConnected__header">
        <HeaderConnected />
      </div>
      <div className="LayoutConnected__content">
        <div
          className={`LayoutConnected__content__sidebar ${
            menu ? '-visible' : ''
          }`}
        >
          <Menu />
        </div>
        <div className="LayoutConnected__content__children">{children}</div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

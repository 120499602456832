import React from 'react';
import './Submit.scss';

export default function Submit({ children, ...props }) {
  return (
    <button type="submit" className="Submit" {...props}>
      {children}
    </button>
  );
}

import React, {useEffect} from 'react';
import LayoutConnected from '../../components/LayoutConnected/LayoutConnected';
import './PaymentAccepted.scss';
import {Link} from "react-router-dom";

export default function PaymentAccepted() {
  return (
    <LayoutConnected>
      <div className="PaymentAccepted">
        <p className="PaymentAccepted__text">
          Votre paiement a été accepté !<br/>
          <Link to="/home">Retour à la page d'accueil</Link>
        </p>
      </div>
    </LayoutConnected>
  );
}

import React, { useEffect, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import AppContext from '../../AppContext';
import { Submit, Input, Checkbox } from '../../components/Form';
import LayoutSubscription from '../../components/LayoutSubscription/LayoutSubscription';
import './ValidatePersonalInfos.scss';
import numeroCristal from '../../img/n-cristal.png';

function ValidatePersonalInfos(props) {
  const { service } = useContext(AppContext);
  const [userInfos, setUser] = useState([]);
  const {
    register,
    handleSubmit,
    setError,
    errors,
    clearErrors,
    setValue,
  } = useForm({
    mode: 'onBlur',
  });

  const firstConnectionUserInfosService = (data) => {
    fetch(`${process.env.REACT_APP_WEBSERVICES}/account`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'TD-SECRET-KEY': localStorage.getItem('auth_token'),
      },
      body: JSON.stringify({
        civ: 'Mr',
        firstName: data.firstname,
        lastName: data.lastname,
        company: data.company,
        phone: data.telephone,
        phone2: data.mobile,
        street: data.adresse,
        complement: data.complement_adresse,
        city: data.ville,
        postalCode: data.code_postal,
        country: 'FR',
        password: data.password,
      }),
    })
      .then((response) => {
        return response.text();
      })
      .then((data) => {
        if (data) {
          const result = JSON.parse(data);
          setError('validation', {
            type: 'manual',
            message: result.message,
          });
        } else {
          props.history.push('/home');
          document.location.reload(true);
        }
      })
      .catch((error) => {});
  };

  const migrationUserInfosService = (data) => {
    fetch(`${process.env.REACT_APP_WEBSERVICES}/account_migration/migrate`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        civ: 'Mr',
        firstName: data.firstname,
        lastName: data.lastname,
        company: data.company,
        phone: data.telephone,
        phone2: data.mobile,
        password: data.password,
        token: localStorage.getItem('email-approvement-token'),
        street: data.adresse,
        complement: data.complement_adresse,
        city: data.ville,
        postalCode: data.code_postal,
        country: 'FR',
      }),
    })
      .then((response) => {
        return response.text();
      })
      .then((data) => {
        let msg;
        if (data) {
          localStorage.removeItem('email-approvement-password');
          msg = JSON.parse(data);
          if (msg.secretKey) {
            localStorage.setItem('auth_token', msg.secretKey);
            props.history.push('/home');
            document.location.reload(true);
          } else {
            props.history.push('/login');
          }
        }
      })
      .catch((error) => {});
  };

  const onSubmit = (data) => {
    if (data.password === data['approve-password']) {
      if (data.telephone !== '' || data.mobile !== '') {
        const userAuth = JSON.parse(localStorage.getItem('auth'));

        if (userAuth && userAuth.isBillingInfoConfirmed === false) {
          firstConnectionUserInfosService(data);
        } else {
          migrationUserInfosService(data);
        }
      } else {
        setError('nophone', {
          type: 'manual',
          message: 'Veuillez renseigner au moins un numéro de téléphone',
        });
      }
    } else {
      setError('nomatch', {
        type: 'manual',
        message: 'Les mots de passe ne correspondent pas',
      });
    }

    setTimeout(() => {
      clearErrors();
    }, 5000);
  };

  useEffect(() => {
    const userAuth = JSON.parse(localStorage.getItem('auth'));
    if (userAuth && userAuth.isBillingInfoConfirmed === false) {
      service('/account', 'GET').then((data) => {
        setUser(data.user);
      });
    } else {
      fetch(`${process.env.REACT_APP_WEBSERVICES}/account_migration/migrate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: localStorage.getItem('email-approvement-token'),
        }),
      })
        .then((response) => {
          return response.text();
        })
        .then((data) => {
          setUser(JSON.parse(data));
        })
        .catch((error) => {});
    }
  }, []);

  const password = localStorage.getItem('email-approvement-password');
  useEffect(() => {
    if (password) {
      setValue('password', password);
      setValue('approve-password', password);
    }
  }, [password]);

  return (
    <LayoutSubscription>
      <div className="ValidatePersonalInfos">
        <div className="ValidatePersonalInfos__content">
          <h1 className="ValidatePersonalInfos__title">
            Validez ou modifiez vos informations
          </h1>
          <div className="ValidatePersonalInfos__connection">
            <form
              className="ValidatePersonalInfos__form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="ValidatePersonalInfos__radios">
                <div className="radio">
                  <input id="Mr" type="radio" name="civ" value="Mr" checked />
                  <label htmlFor="civ">M.</label>
                </div>
                <div className="radio">
                  <input id="Mme" type="radio" name="civ" value="Mme" />
                  <label htmlFor="Mme">Mme</label>
                </div>
              </div>
              <Input
                label="Nom"
                name="lastname"
                placeholder="ex: Dupond"
                register={register({ required: true })}
                errors={errors}
                className="ValidatePersonalInfos__input"
                defaultValue={userInfos.lastName ? userInfos.lastName : ''}
              />
              <Input
                label="Prénom"
                name="firstname"
                placeholder="ex: Henri"
                register={register({ required: true })}
                errors={errors}
                className="ValidatePersonalInfos__input"
                defaultValue={userInfos.firstName ? userInfos.firstName : ''}
              />
              <Input
                label="Entreprise"
                name="company"
                placeholder="ex: Mon entreprise"
                register={register({ required: false })}
                errors={errors}
                className="ValidatePersonalInfos__input"
                defaultValue={userInfos.company ? userInfos.company : ''}
              />
              <Input
                label="Adresse"
                name="adresse"
                placeholder="ex: 37 grande rue"
                register={register({ required: true })}
                errors={errors}
                className="ValidatePersonalInfos__input"
                defaultValue={userInfos.address ? userInfos.address.street : ''}
              />
              <Input
                label="Complément d'adresse"
                labelInfos="(facultatif)"
                name="complement_adresse"
                placeholder="ex: Bâtiment, Allée..."
                register={register({ required: false })}
                errors={errors}
                className="ValidatePersonalInfos__input"
                defaultValue={
                  userInfos.address ? userInfos.address.complement : ''
                }
              />
              <Input
                type="number"
                label="Code postal"
                name="code_postal"
                min="0"
                max="99999"
                placeholder="ex: 91021"
                register={register({ required: true })}
                errors={errors}
                className="ValidatePersonalInfos__input__small"
                defaultValue={
                  userInfos.address ? userInfos.address.postalCode : ''
                }
              />
              <Input
                label="Ville"
                name="ville"
                placeholder="ex: Arpajon"
                register={register({ required: true })}
                errors={errors}
                className="ValidatePersonalInfos__input"
                defaultValue={userInfos.address ? userInfos.address.city : ''}
              />
              <br />
              <br />
              <Input
                type="tel"
                label="Téléphone fixe"
                labelInfos="(au moins un des deux)"
                name="telephone"
                placeholder="ex: 06 76 54 32 19"
                min-length="10"
                register={register({ required: false })}
                errors={errors}
                className="ValidatePersonalInfos__input__small"
                defaultValue={userInfos.address ? userInfos.phone : ''}
              />
              <Input
                type="tel"
                label="Téléphone mobile"
                labelInfos="(au moins un des deux)"
                name="mobile"
                placeholder="ex: 06 76 54 32 19"
                min-length="10"
                register={register({ required: false })}
                errors={errors}
                className="ValidatePersonalInfos__input__small"
                defaultValue={userInfos.address ? userInfos.phone2 : ''}
              />

              {localStorage.getItem('email-approvement-password') ? (
                <Input
                  type="hidden"
                  name="password"
                  register={register({ required: true })}
                />
              ) : (
                <>
                  <h1 className="ValidatePersonalInfos__subtitle">
                    Choisissez un mot de passe
                  </h1>
                  <Input
                    type="password"
                    label="Mot de passe"
                    placeholder="Confirmez votre mot de passe"
                    pattern="^(?=.*[A-Z])(?=.*\d)([-+!*$@%_\w]{8,})$"
                    name="password"
                    register={register({ required: true })}
                    errors={errors}
                  />
                  <p className="ValidatePersonalInfos__input__help">
                    Minimum 8 caractères, 1 majuscule, 1 numéro
                  </p>
                </>
              )}
              <br />
              <Input
                type={
                  localStorage.getItem('email-approvement-password')
                    ? 'hidden'
                    : 'password'
                }
                label="Confirmez votre mot de passe"
                placeholder="Confirmez votre mot de passe"
                pattern="^(?=.*[A-Z])(?=.*\d)([-+!*$@%_\w]{8,})$"
                name="approve-password"
                register={register({ required: true })}
                errors={errors}
              />
              <Checkbox
                label="Je souhaite souscrire à la newsletter et aux éventuelles offres commerciales de Transdev Park"
                name="newsletter"
                register={register}
                errors={errors}
              />
              <Checkbox
                label="J’autorise Transdev Park à utiliser mes données personnelles dans le cadre de la <a target='_blank' href='https://www.transdevpark.com/footer/mentions_legales'>politique de gestion des données personnelles</a> présentes sur ce site"
                name="data"
                register={register({ required: true })}
                errors={errors}
              />

              {errors.nomatch && (
                <p className="ValidatePersonalInfos__error">
                  {errors.nomatch.message}
                </p>
              )}
              {errors.nophone && (
                <p className="ValidatePersonalInfos__error">
                  {errors.nophone.message}
                </p>
              )}
              {errors.validation && (
                <p className="ValidatePersonalInfos__error">
                  {errors.validation.message}
                </p>
              )}
              <Submit>Valider mes informations</Submit>
            </form>
          </div>
          <div className="ValidatePersonalInfos__help">
            <p className="ValidatePersonalInfos__help__bold">
              Vous avez besoin d’aide pour vous connecter à votre espace client
              ?
            </p>
            <p className="ValidatePersonalInfos__help__text">
              Vous pouvez appeler le N° Cristal ci-dessous
            </p>
            <a href="tel:0970609955">
              <img
                src={numeroCristal}
                alt="numero cristal: 09 70 60 99 55"
                className="ValidatePersonalInfos__imgPhoneNumber"
              />
            </a>
          </div>
        </div>
      </div>
    </LayoutSubscription>
  );
}

export default withRouter(ValidatePersonalInfos);

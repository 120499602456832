import React, { useRef } from 'react';
import './Checkbox.scss';

const parse = require('html-react-parser');

export default function Checkbox({
  name,
  label,
  register,
  errors = {},
  patternErrorMessage,
  ...props
}) {
  const uid = useRef(`Checkbox-${Math.round(Math.random() * 1000000)}`);

  const parsedLabel = parse(label);
  return (
    <div className={`Checkbox ${errors[name] ? '-error' : ''}`}>
      <label htmlFor={uid.current} className="Checkbox__group">
        <span className="Checkbox__group">
          <input
            type="Checkbox"
            name={name}
            id={uid.current}
            ref={register}
            className="Checkbox__field"
            {...props}
          />
          <span className="Checkbox__status" />
          <span className="Checkbox__label">{parsedLabel}</span>
        </span>
      </label>

      <div className="Checkbox__errors">
        {errors[name] && errors[name].type === 'required' && (
          <span className="Checkbox__error">Ce champ est requis.</span>
        )}
      </div>
    </div>
  );
}

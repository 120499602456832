import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import AppContext from '../../AppContext';
import user from '../../img/user.svg';
import './Menu.scss';

export default function Menu() {
  const { toggleMenu, service } = useContext(AppContext);
  const [userInfos, setUserInfos] = useState([]);

  useEffect(() => {
    service('/account', 'GET').then((data) => {
      if (data.user) setUserInfos(data.user);
    });
  }, []);

  return (
    <div className="Menu">
      <section className="Menu__header">
        <div className="Menu__user">
          <img src={user} alt="" className="Menu__user__icon" />
          {userInfos.firstName ? userInfos.firstName : ''}{' '}
          {userInfos.lastName ? userInfos.lastName : ''}
        </div>
        <Link className="Menu__text" to="/login">
          Déconnexion
        </Link>
      </section>
      <section className="Menu__section">
        <div className="Menu__title">Mes informations</div>
        <div className="Menu__text">
          {userInfos.address ? userInfos.address.street : ''}
        </div>
        <div className="Menu__text">
          {userInfos.address ? userInfos.address.postalCode : ''}{' '}
          {userInfos.address ? userInfos.address.city : ''}
        </div>
        <div className="Menu__text">
          {userInfos.phone} {userInfos.phone2}
        </div>
        <Link
          className="Menu__link"
          to="/update-personal-infos"
          onClick={toggleMenu}
        >
          Modifier
        </Link>
      </section>
      <section className="Menu__section">
        <div className="Menu__text">{userInfos.email}</div>
        <Link className="Menu__link" to="/update-email" onClick={toggleMenu}>
          Modifier
        </Link>
      </section>
    </div>
  );
}

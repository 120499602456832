import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, withRouter } from 'react-router-dom';
import { Submit, Input } from '../../components/Form';
import LayoutSubscription from '../../components/LayoutSubscription/LayoutSubscription';
import './PasswdForgot.scss';

function PasswdForgot(props) {
  const token = props.match.params.tokenId;
  const [successMessage, setSuccess] = useState('');
  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    localStorage.removeItem('new-password-token');
    if (token && token !== 'form') {
      localStorage.setItem('new-password-token', token);
      fetch(`${process.env.REACT_APP_WEBSERVICES}/forgot_password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
        }),
      })
        .then((response) => {
          return response.text();
        })
        .then((data) => {
          props.history.push('/update-password');
        })
        .catch((error) => {});
    }
  }, []);

  const onSubmit = (data) => {
    fetch(`${process.env.REACT_APP_WEBSERVICES}/forgot_password/ask`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: data.email,
      }),
    })
      .then((response) => {
        return response.text();
      })
      .then((data) => {
        if (data === '') {
          setSuccess(
            'Un email vous a été transmis afin de valider vos modifications!'
          );
        }

        JSON.parse(data);
      })
      .catch((error) => {});
  };

  return (
    <LayoutSubscription>
      <div className="PasswdForgot">
        <div className="PasswdForgot__content">
          <h1 className="PasswdForgot__title">
            Pour des raisons de sécurité, réinitialisez votre mot de passe
          </h1>
          <div className="PasswdForgot__connection">
            <p className="PasswdForgot__infos">
              Seule une adresse email déjà transmise à Transdev Park vous
              permettra de renouveler votre mot de passe.
            </p>

            <form
              className="PasswdForgot__form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Input
                type="email"
                label="Adresse E-mail"
                name="email"
                placeholder="jean.dupont@gmail.com"
                register={register({ required: true })}
                errors={errors}
              />
              <Submit>Réinitialiser mon mot de passe</Submit>
            </form>
            {successMessage && (
              <p className="PasswdForgot__success">{successMessage}</p>
            )}
          </div>
        </div>
        <p className="PasswdForgot__footer">
          <Link to="/login">Revenir à la page de connexion</Link>
        </p>
      </div>
    </LayoutSubscription>
  );
}

export default withRouter(PasswdForgot);

import React from 'react';
import pin from '../../img/pin.svg';
import tchat from '../../img/tchat.svg';
import './Footer.scss';

export default function Footer() {
  return (
    <div className="Footer">
      <div className="Footer__content">
        <span className="Footer__wrap">
          À retrouver sur le site de{' '}
          <a
            href="https://www.transdevpark.com"
            target="_blank"
            rel="noopener noreferrer"
            className="Footer__link"
          >
            transdevpark.com
          </a>{' '}
          :{' '}
        </span>
        <a
            href="https://www.transdevpark.com/parkings_et_voiries"
            rel="noopener noreferrer"
            target="_blank"
            className="Footer__link2">
          <img src={pin} alt="" className="Footer__logo" />
          Trouver un parking
        </a>
        <a
            href="https://www.transdevpark.com/outils/faq"
            rel="noopener noreferrer"
            target="_blank"
            className="Footer__link2">
          <img src={tchat} alt="" className="Footer__logo" />
          F.A.Q.
        </a>
      </div>
    </div>
  );
}

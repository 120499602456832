import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import LayoutConnected from '../../components/LayoutConnected/LayoutConnected';
import { Cancel, Submit, Input } from '../../components/Form';
import './UpdateEmail.scss';

export default function UpdateEmail() {
  const [successMessage, setSuccess] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { register, handleSubmit, setError, errors, clearErrors } = useForm({
    mode: 'onBlur',
  });

  const onSubmit = (data) => {
    setSuccess('');
    setErrorMessage('');
    if (data.email === data['approve-email']) {
      fetch(`${process.env.REACT_APP_WEBSERVICES}/account/email`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'TD-SECRET-KEY': localStorage.getItem('auth_token'),
        },
        body: JSON.stringify({
          email: data.email,
          password: data.password,
        }),
      })
        .then((response) => {
          if (response.status === 403) {
            setErrorMessage('Le mot de passe est erroné');
          }
          return response.text();
        })
        .then((data) => {
          if (data === '') {
            setSuccess(
              'Un email vous a été transmis afin de valider vos modifications !'
            );
          }

          JSON.parse(data);
        })
        .catch((error) => {});
    } else {
      setError('nomatch', {
        type: 'manual',
        message: 'Les adresses emails ne correspondent pas',
      });
    }

    setTimeout(() => {
      clearErrors();
    }, 2000);
  };

  return (
    <LayoutConnected>
      <div className="UpdateEmail">
        <h1 className="UpdateEmail__title">Modifier mon email</h1>
        <Link className="UpdateEmail__homeBtn>" to="/home">
          &lt; Retour à l'accueil
        </Link>
      </div>
      <form className="UpdateEmail__form" onSubmit={handleSubmit(onSubmit)}>
        <Input
          type="email"
          label="E-mail"
          name="email"
          placeholder="jean.dupont@gmail.com"
          pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
          register={register({ required: true })}
          errors={errors}
          className="UpdateEmail__input"
        />
        <Input
          type="email"
          label="Confirmez votre E-mail"
          name="approve-email"
          placeholder="jean.dupont@gmail.com"
          pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
          register={register({ required: true })}
          errors={errors}
          className="UpdateEmail__input"
        />
        <br />
        <br />
        <Input
          type="password"
          label="Mot de passe"
          name="password"
          placeholder="Votre mot de passe"
          register={register({ required: true })}
          errors={errors}
          className="UpdateEmail__input"
        />

        {errors.nomatch && (
          <p className="UpdateEmail__error">{errors.nomatch.message}</p>
        )}
        {errorMessage && <p className="UpdateEmail__error">{errorMessage}</p>}
        {successMessage && (
          <p className="UpdateEmail__success">{successMessage}</p>
        )}
        <div className="UpdateEmail__buttonsContainer">
          <Cancel>Annuler</Cancel>
          <Submit>Valider</Submit>
        </div>
      </form>
    </LayoutConnected>
  );
}

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { withRouter, Link } from 'react-router-dom';
import { Submit, Cancel } from '../../components/Form';
import LayoutSubscription from '../../components/LayoutSubscription/LayoutSubscription';
import './SubscriptionMergeApprovement.scss';
import numeroCristal from '../../img/n-cristal.png';

function SubscriptionMergeApprovement(props) {
  const [emailSubmitted, setEmailSubmitted] = useState('');
  const [userInfos, setUserInfos] = useState([]);
  const [successMessage, setSuccess] = useState('');
  const token = props.match.params.tokenId;

  const { handleSubmit } = useForm({
    mode: 'onBlur',
  });

  const onSubmit = (data) => {
    if (token) {
      fetch(`${process.env.REACT_APP_WEBSERVICES}/account_migration/merge`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
        }),
      })
        .then((response) => {
          return response.text();
        })
        .then((data) => {
          if (!data) {
            setSuccess(
              'Votre compte a bien été fusionné. Vous pouvez désormais vous rendre sur la page authentification et vous connecter avec votre compte comte existant.'
            );
          }
        })
        .catch((error) => {});
    }
  };

  const onCancel = (data) => {
    props.history.push('/approve-email');
  };
  useEffect(() => {
    setEmailSubmitted(localStorage.getItem('userEmail'));
    setUserInfos(JSON.parse(localStorage.getItem('userInfos'))[0]);
    if (token) {
      fetch(`${process.env.REACT_APP_WEBSERVICES}/account_migration/merge`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
        }),
      })
        .then((response) => {
          return response.text();
        })
        .then((data) => {})
        .catch((error) => {});
    }
  }, []);
  return (
    <LayoutSubscription>
      <div className="SubscriptionMergeApprovement">
        <div className="SubscriptionMergeApprovement__content">
          <h1 className="SubscriptionMergeApprovement__title">
            Confirmez la fusion de vos abonnements
          </h1>
          <p className="SubscriptionMergeApprovement__subtitle">
            Pour rappel, voici l’abonnement ({userInfos.id}) que vous allez
            fusionner sur votre compte lié à l’adresse email{' '}
            <b>{emailSubmitted}</b>
          </p>
          <div className="SubscriptionMergeApprovement__connection">
            <div className="SubscriptionMergeApprovement__recap__container">
              <form
                className="SubscriptionMergeApprovement__form"
                onSubmit={handleSubmit(onSubmit)}
              >
                {!successMessage && (
                  <div>
                    <Submit className="SubscriptionMergeApprovement__submit">
                      Je confirme la fusion de mes abonnements
                    </Submit>
                    <br />
                    <br />
                    <Cancel onClick={onCancel}>
                      Annuler la procédure de fusion des abonnements
                    </Cancel>
                  </div>
                )}
              </form>
            </div>
            {successMessage && (
              <div>
                <p className="SubscriptionMerge__success">{successMessage}</p>
                <Link to="/login" className="SubscriptionMerge__success">
                  Page d'authentification >>
                </Link>
              </div>
            )}
          </div>
          <div className="SubscriptionMergeApprovement__help">
            <p className="SubscriptionMergeApprovement__help__bold">
              Vous avez besoin d’aide pour vous connecter à votre espace client
              ?
            </p>
            <p className="SubscriptionMergeApprovement__help__text">
              Vous pouvez appeler le N° Cristal ci-dessous
            </p>
            <a href="tel:0970609955">
              <img
                src={numeroCristal}
                alt="numero cristal: 09 70 60 99 55"
                className="SubscriptionMergeApprovement__imgPhoneNumber"
              />
            </a>
          </div>
        </div>
      </div>
    </LayoutSubscription>
  );
}

export default withRouter(SubscriptionMergeApprovement);

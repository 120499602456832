import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import AppContext from '../../AppContext';
import LayoutConnected from '../../components/LayoutConnected/LayoutConnected';
import { Cancel, Submit, Input } from '../../components/Form';
import './UpdatePersonalInfos.scss';

export default function UpdatePersonalInfos(props) {
  const { service } = useContext(AppContext);
  const [userInfos, setUserInfos] = useState([]);
  const [successMessage, setSuccess] = useState('');
  const { register, handleSubmit, setError, errors, clearErrors } = useForm({
    mode: 'onBlur',
  });

  const onSubmit = (data) => {
    if (data.telephone !== '' || data.mobile !== '') {
      fetch(`${process.env.REACT_APP_WEBSERVICES}/account`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'TD-SECRET-KEY': localStorage.getItem('auth_token'),
        },
        body: JSON.stringify({
          civ: userInfos.civ,
          firstName: userInfos.firstName,
          lastName: userInfos.lastName,
          company: userInfos.company,
          phone: data.telephone,
          phone2: data.mobile,
          street: data.adresse,
          complement: data.complement_adresse,
          city: data.ville,
          postalCode: data.code_postal,
          country: userInfos.address.country,
        }),
      })
        .then((response) => {
          return response.text();
        })
        .then((data) => {
          setSuccess('Vos informations ont bien été mises à jour');
          setTimeout(() => {
            setSuccess('');
          }, 4000);

          JSON.parse(data);
        })
        .catch((error) => {});
    } else {
      setError('nophone', {
        type: 'manual',
        message: 'Veuillez renseigner au moins un numéro de téléphone',
      });
    }
    setTimeout(() => {
      clearErrors();
    }, 2000);
  };

  useEffect(() => {
    service('/account', 'GET').then((data) => {
      if (data.error) return;
      setUserInfos(data.user);
    });
  }, []);

  return (
    <LayoutConnected>
      <div className="UpdatePersonalInfos">
        <h1 className="UpdatePersonalInfos__title">
          Modifier mes informations
        </h1>
        <Link className="UpdatePersonalInfos__homeBtn>" to="/home">
          &lt; Retour à l'accueil
        </Link>
      </div>
      <form
        className="UpdatePersonalInfos__form"
        onSubmit={handleSubmit(onSubmit)}
        // noValidate
      >
        <Input
          label="Adresse"
          name="adresse"
          defaultValue={userInfos.address ? userInfos.address.street : ''}
          placeholder="ex: 37 grande rue"
          register={register({ required: true })}
          errors={errors}
          className="UpdatePersonalInfos__input"
        />
        <Input
          label="Complément d'adresse"
          labelInfos="(facultatif)"
          name="complement_adresse"
          defaultValue={userInfos.address ? userInfos.address.complement : ''}
          placeholder="ex: Bâtiment, Allée..."
          register={register({ required: false })}
          errors={errors}
          className="UpdatePersonalInfos__input"
        />
        <Input
          type="number"
          label="Code postal"
          name="code_postal"
          defaultValue={userInfos.address ? userInfos.address.postalCode : ''}
          min="0"
          max="99999"
          placeholder="ex: 91021"
          register={register({ required: true })}
          errors={errors}
          className="UpdatePersonalInfos__input"
        />
        <Input
          label="Ville"
          name="ville"
          defaultValue={userInfos.address ? userInfos.address.city : ''}
          placeholder="ex: Arpajon"
          register={register({ required: true })}
          errors={errors}
          className="UpdatePersonalInfos__input"
        />

        <Input
          type="tel"
          label="Téléphone fixe"
          labelInfos="(au moins un des deux)"
          name="telephone"
          defaultValue={userInfos.address ? userInfos.phone : ''}
          placeholder="ex: 06 76 54 32 19"
          inputMode="tel"
          register={register({ required: false })}
          errors={errors}
          pattern="^([0-9]{2}\s?){5}$"
          className="UpdatePersonalInfos__input"
        />
        <Input
          type="tel"
          label="Téléphone mobile"
          labelInfos="(au moins un des deux)"
          name="mobile"
          defaultValue={userInfos.address ? userInfos.phone2 : ''}
          placeholder="ex: 06 76 54 32 19"
          register={register({ required: false })}
          errors={errors}
          inputMode="tel"
          pattern="^([0-9]{2}\s?){5}$"
          className="UpdatePersonalInfos__input"
        />

        {errors.nophone && (
          <p className="UpdatePersonalInfos__error">{errors.nophone.message}</p>
        )}
        {successMessage && (
          <p className="UpdatePersonalInfos__success">{successMessage}</p>
        )}
        <div className="UpdatePersonalInfos__buttonsContainer">
          <Cancel>Annuler</Cancel>
          <Submit>Valider</Submit>
        </div>
      </form>
    </LayoutConnected>
  );
}

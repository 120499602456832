import React, {useEffect} from 'react';
import LayoutConnected from '../../components/LayoutConnected/LayoutConnected';
import './PaymentCancelled.scss';
import {Link} from "react-router-dom";

export default function PaymentCancelled() {
  return (
    <LayoutConnected>
      <div className="PaymentCancelled">
        <p className="PaymentCancelled__text">
          Paiement annulé !<br/>
          <Link to="/home">Retour à la page d'accueil</Link>
        </p>
      </div>
    </LayoutConnected>
  );
}

import React from 'react';
import './LayoutSubscription.scss';
import logo from "../../img/logo-transdev.png";

export default function LayoutSubscription({ children }) {
  return (
    <div className="LayoutSubscription">
      <div className="LayoutSubscription__header">
        <div className="LayoutSubscription__Header__container">
          <div className="LayoutSubscription__Header__content">
            <a href="/login" className="LayoutSubscription__Header__home">
              <img src={logo} alt="logo transdev Park" className="LayoutSubscription__Header__logo" />
            </a>
          </div>
        </div>
      </div>
      <div className="LayoutSubscription__content">{children}</div>
    </div>
  );
}

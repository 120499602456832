import React, { useEffect } from 'react';
import './EmailUpdated.scss';
import { Link } from 'react-router-dom';
import LayoutSubscription from '../../components/LayoutSubscription/LayoutSubscription';

export default function EmailUpdated(props) {
  const token = props.match.params.tokenId;

  useEffect(() => {
    fetch(`${process.env.REACT_APP_WEBSERVICES}/email_confirmation`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token,
      }),
    })
      .then((response) => {
        return response.text();
      })
      .then((data) => {})
      .catch((error) => {});
  }, []);
  return (
    <LayoutSubscription>
      <div className="EmailUpdated">
        <p className="EmailUpdated__text">
          Votre email a bien été validé !{' '}
          <Link to="/login">Retour à la page de login</Link>
        </p>
      </div>
    </LayoutSubscription>
  );
}

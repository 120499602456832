import React, { useState, useRef } from 'react';
import './Input.scss';

export default function Input({
  type: defaultType,
  name,
  label,
  labelInfos,
  register,
  errors = {},
  pattern,
  patternErrorMessage,
  ...props
}) {
  const uid = useRef(`input-${Math.round(Math.random() * 1000000)}`);
  const [type, setType] = useState(defaultType);

  const togglePasswordType = () => {
    setType(type === 'password' ? 'text' : 'password');
  };

  return (
    <div className={`Input ${errors[name] ? '-error' : ''}`}>
      <label htmlFor={uid.current}>
        <span className="Input__label">{label} <span className="Input__label_infos">{labelInfos}</span></span>
        <span className="Input__wrap">
          <input
            type={type || 'text'}
            name={name}
            id={uid.current}
            ref={register}
            pattern={pattern}
            className="Input__field"
            {...props}
          />
          {defaultType === 'password' && (
            <button
              type="button"
              className="Input__togglePassword"
              onClick={() => togglePasswordType()}
            >
              {type === 'password' ? 'Afficher' : 'Masquer'}
            </button>
          )}
        </span>
      </label>

      <div className="Input__errors">
        {errors[name] && errors[name].type === 'pattern' && (
          <span className="Input__error">
            {patternErrorMessage ||
              'Merci de renseigner ce champ correctement.'}
          </span>
        )}
        {errors[name] && errors[name].type === 'required' && (
          <span className="Input__error">Ce champ est requis.</span>
        )}
      </div>
    </div>
  );
}

import React, { useEffect } from 'react';
import './EmailApproved.scss';
import LayoutSubscription from '../../components/LayoutSubscription/LayoutSubscription';

export default function EmailApproved(props) {
  const token = props.match.params.tokenId;

  useEffect(() => {
    if (token) {
      localStorage.setItem('email-approvement-token', token);
      props.history.push('/validate-personal-infos');
    }
  }, []);
  return (
    <LayoutSubscription>
      <div className="EmailUpdated">
        <p className="EmailUpdated__text">
          Votre email a bien été renseigné !
        </p>
      </div>
    </LayoutSubscription>
  );
}

import React from 'react';
import Header from '../Header/Header';
import './Layout.scss';

export default function Layout({ children }) {
  return (
    <div className="Layout">
      <div className="Layout__header">
        <Header />
      </div>
      <div className="Layout__content">{children}</div>
    </div>
  );
}

import React from 'react';
import './Cancel.scss';

export default function Cancel({ children, ...props }) {
  return (
    <button type="reset" className="Cancel" {...props}>
      {children}
    </button>
  );
}

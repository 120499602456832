import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import { Submit, Input } from '../../components/Form';
import LayoutSubscription from '../../components/LayoutSubscription/LayoutSubscription';
import './ApproveEmail.scss';

function ApproveEmail(props) {
  const userAuth = JSON.parse(localStorage.getItem('auth'));
  const [defaultEMail, setDefaultEmail] = useState(
    userAuth && userAuth.isEmailVerified === false
      ? localStorage.getItem('login')
      : ''
  );
  const [successMessage, setSuccess] = useState('');
  const { register, handleSubmit, setError, errors, clearErrors } = useForm({
    mode: 'onBlur',
  });

  const migrationEmailService = (data) => {
    fetch(`${process.env.REACT_APP_WEBSERVICES}/account_migration/email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: data.email,
        token: localStorage.getItem('migration_token'),
        type: 'migration',
      }),
    })
      .then((response) => {
        return response.text();
      })
      .then((data) => {
        let msg;
        if (data) {
          msg = JSON.parse(data);
          if (Array.isArray(msg)) {
            localStorage.setItem('userInfos', JSON.stringify(msg));
            props.history.push('/subscription-merge');
          }
        } else {
          setSuccess('Un email de validation vous a été envoyé');
        }
      })
      .catch((error) => {});
  };

  const firstConnectionEmailService = (data) => {
    fetch(`${process.env.REACT_APP_WEBSERVICES}/account/email`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'TD-SECRET-KEY': localStorage.getItem('auth_token'),
      },
      body: JSON.stringify({
        email: data.email.toString(),
      }),
    })
      .then((response) => {
        if (response.status === 400) {
          setError('nomatch', {
            type: 'manual',
            message: 'Votre email existe déjà',
          });
        }
        return response.text();
      })
      .then((data) => {
        if (data === '') {
          localStorage.removeItem('auth');
          localStorage.removeItem('auth_token');
          setSuccess(
            "Un email vous a été transmis. Merci de vous reconnecter après avoir cliquer sur le lien d'activation"
          );
        }

        JSON.parse(data);
      })
      .catch((error) => {});
  };

  const onSubmit = (data) => {
    if (data.email === data['email-approve']) {
      localStorage.setItem('userEmail', data.email);

      if (userAuth && userAuth.isEmailVerified === false) {
        firstConnectionEmailService(data);
      } else {
        migrationEmailService(data);
      }
    } else {
      setError('nomatch', {
        type: 'manual',
        message: 'Les adresses emails ne correspondent pas',
      });
      setTimeout(() => {
        clearErrors();
      }, 2000);
    }
  };

  return (
    <LayoutSubscription>
      <div className="ApproveEmail">
        <div className="ApproveEmail__content">
          <h1 className="ApproveEmail__title">
            Renseignez votre adresse e-mail
          </h1>
          <div className="ApproveEmail__connection">
            <p className="ApproveEmail__infos">
              Pour des raisons de sécurité, merci de renseigner votre adresse
              E-mail afin de vous connecter à votre espace adhérent.
            </p>

            <form
              className="ApproveEmail__form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Input
                type="email"
                label="Adresse E-mail"
                name="email"
                defaultValue={defaultEMail}
                placeholder="jean.dupont@gmail.com"
                pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                register={register({ required: true })}
                errors={errors}
              />
              <Input
                type="email"
                label="Confirmez votre adresse E-mail"
                name="email-approve"
                placeholder="jean.dupont@gmail.com"
                pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                register={register({ required: true })}
                errors={errors}
              />

              {errors.nomatch && (
                <p className="ApproveEmail__error">{errors.nomatch.message}</p>
              )}
              <Submit>Valider mon E-mail</Submit>

              {successMessage && (
                <p className="ApproveEmail__success">{successMessage}</p>
              )}
            </form>
          </div>
        </div>
      </div>
    </LayoutSubscription>
  );
}

export default withRouter(ApproveEmail);

import React, { useEffect, useState } from 'react';

const AppContext = React.createContext();

const MyAppProvider = ({ children }) => {
  /* loading / connected / disconnected */
  const [isConnected, setConnected] = useState('loading');
  const [menu, setMenu] = useState(false);
  const toggleMenu = () => setMenu((e) => !e);

  function service(route, method, body) {
    return fetch(`${process.env.REACT_APP_WEBSERVICES}${route}`, {
      headers: {
        'Content-Type': 'application/json',
        'TD-SECRET-KEY': localStorage.getItem('auth_token'),
      },
      method,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          return Promise.reject(data.error);
        }
        return data;
      })
      .catch((error) => {
        console.error(error);
        setConnected('disconnected');
        return Promise.reject(error);
      });
  }

  useEffect(() => {
    if (localStorage.getItem('auth_token')) {
      fetch(`${process.env.REACT_APP_WEBSERVICES}/account`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'TD-SECRET-KEY': localStorage.getItem('auth_token'),
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.error) setConnected('disconnected');
          else setConnected('connected');
        })
        .catch(() => {
          setConnected('disconnected');
        });
    } else {
      setConnected('disconnected');
    }
  }, []);

  return (
    <AppContext.Provider
      value={{ menu, toggleMenu, isConnected, setConnected, service }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
export { MyAppProvider };

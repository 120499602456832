import React, { useRef } from 'react';
import './Select.scss';

export default function Select({
  children,
  type: defaultType,
  name,
  label,
  register,
  errors = {},
  patternErrorMessage,
  ...props
}) {
  const uid = useRef(`input-${Math.round(Math.random() * 1000000)}`);

  return (
    <div className={`Select ${errors[name] ? '-error' : ''}`}>
      <label htmlFor={uid.current}>
        <span className="Select__label">{label}</span>
        <span className="Select__wrap">
          <select
            type="text"
            name={name}
            id={uid.current}
            ref={register}
            className="Select__field"
            {...props}
          >
            {children}
          </select>
        </span>
      </label>

      <div className="Select__errors">
        {errors[name] && errors[name].type === 'pattern' && (
          <span className="Select__error">
            {patternErrorMessage ||
              'Merci de renseigner ce champ correctement.'}
          </span>
        )}
        {errors[name] && errors[name].type === 'required' && (
          <span className="Select__error">Ce champ est requis.</span>
        )}
      </div>
    </div>
  );
}

import React from 'react';
import './Header.scss';
import logo from '../../img/logo-transdev.png';

export default function Header() {
  return (
    <header className="Header">
      <div className="Header__container">
        <div className="Header__content">
          <a href="/" className="Header__home">
            <img src={logo} alt="logo transdev Park" className="Header__logo" />
          </a>
          <p className="Header__welcome">
            Bienvenue sur le nouvel espace client de Transdev Park
          </p>
          <a
            href="https://www.transdevpark.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="Header__button"
          >
            Retour au site
          </a>
        </div>
      </div>
    </header>
  );
}

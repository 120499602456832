import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.scss';
import { MyAppProvider } from './AppContext';
import Login from './pages/Login/Login';
import PasswdForgot from './pages/PasswdForgot/PasswdForgot';
import Home from './pages/Home/Home';
import UpdatePersonalInfos from './pages/UpdatePersonalInfos/UpdatePersonalInfos';
import UpdateEmail from './pages/UpdateEmail/UpdateEmail';
import ApproveEmail from './pages/ApproveEmail/ApproveEmail';
import ValidatePersonalInfos from './pages/ValidatePersonalInfos/ValidatePersonalInfos';
import SubscriptionMerge from './pages/SubscriptionMerge/SubscriptionMerge';
import SubscriptionMergeApprovement from './pages/SubscriptionMergeApprovement/SubscriptionMergeApprovement';
import EmailUpdated from './pages/EmailApproved/EmailUpdated';
import EmailApproved from './pages/EmailUpdated/EmailApproved';
import PaymentAccepted from './pages/PaymentAccepted/PaymentAccepted';
import PaymentRefused from './pages/PaymentRefused/PaymentRefused';
import PaymentCancelled from './pages/PaymentCancelled/PaymentCancelled';
import UpdatePasswd from './pages/UpdatePasswd/UpdatePasswd';

function App() {
  return (
    <div className="App">
      <MyAppProvider>
        <Router>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route
              exact
              path="/forgot-password/:tokenId"
              component={PasswdForgot}
            />
            <Route exact path="/update-password" component={UpdatePasswd} />
            <Route exact path="/" component={Login} />
            <Route exact path="/home" component={Home} />
            <Route exact path="/approve-email" component={ApproveEmail} />
            <Route
              exact
              path="/validate-personal-infos"
              component={ValidatePersonalInfos}
            />
            <Route
              exact
              path="/subscription-merge"
              component={SubscriptionMerge}
            />
            <Route
              exact
              path="/subscription-merge-approvement/:tokenId"
              component={SubscriptionMergeApprovement}
            />
            <Route
              exact
              path="/update-personal-infos"
              component={UpdatePersonalInfos}
            />
            <Route exact path="/update-email" component={UpdateEmail} />
            <Route
              exact
              path="/email-updated/:tokenId"
              component={EmailUpdated}
            />
            <Route
              exact
              path="/email-approved/:tokenId"
              component={EmailApproved}
            />
            <Route exact path="/payment/accepted" component={PaymentAccepted} />
            <Route exact path="/payment/refused" component={PaymentRefused} />
            <Route
              exact
              path="/payment/cancelled"
              component={PaymentCancelled}
            />
          </Switch>
        </Router>
      </MyAppProvider>
    </div>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import { Submit, Cancel } from '../../components/Form';
import LayoutSubscription from '../../components/LayoutSubscription/LayoutSubscription';
import './SubscriptionMerge.scss';
import numeroCristal from '../../img/n-cristal.png';

function SubscriptionMerge(props) {
  const [emailSubmitted, setEmailSubmitted] = useState('');
  const [userInfos, setUserInfos] = useState([]);
  const [successMessage, setSuccess] = useState('');

  const { handleSubmit } = useForm({
    mode: 'onBlur',
  });

  const onSubmit = (data) => {
    fetch(`${process.env.REACT_APP_WEBSERVICES}/account_migration/email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: emailSubmitted,
        token: localStorage.getItem('migration_token'),
        type: 'merge',
      }),
    })
      .then((response) => {
        return response.text();
      })
      .then((data) => {
        if (!data) {
          setSuccess('Un email de validation vous a été envoyé');
        }
      })
      .catch((error) => {});
  };

  const onCancel = (data) => {
    props.history.push('/approve-email');
  };

  useEffect(() => {
    setEmailSubmitted(localStorage.getItem('userEmail'));
    setUserInfos(JSON.parse(localStorage.getItem('userInfos'))[0]);
  }, []);

  return (
    <LayoutSubscription>
      <div className="SubscriptionMerge">
        <div className="SubscriptionMerge__content">
          <h1 className="SubscriptionMerge__title">
            Voulez-vous fusionner vos abonnements Transdev Park ?
          </h1>
          <p className="SubscriptionMerge__subtitle">
            La fusion des comptes vous permet de retrouver dans votre espace
            client les factures de vos différents abonnements.
          </p>
          <div className="SubscriptionMerge__connection">
            <div className="SubscriptionMerge__recap__container">
              <p className="SubscriptionMerge__recap__text">
                Votre email <b>{emailSubmitted}</b> est déjà utilisé pour le
                compte transdev park suivant :
              </p>
              <div className="SubscriptionMerge__table__container">
                <table className="SubscriptionMerge__table">
                  <tbody>
                    <tr>
                      <th>Identifiant</th>
                      <td>{userInfos.id}</td>
                    </tr>
                    <tr>
                      <th>Parking</th>
                      <td>
                        {userInfos.parkName} - {userInfos.parkAddress}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <p className="SubscriptionMerge__text">
              Si vous <b>lancez la procédure</b> de fusion de vos abonnements,
              vous allez recevoir un email à l'adresse que vous avez
              communiquée. Si vous ne souhaitez pas fusionner vos comptes, il
              vous faudra une autre adresse E-mail.
            </p>
            <form
              className="SubscriptionMerge__form"
              onSubmit={handleSubmit(onSubmit)}
            >
              {!successMessage && (
                <div>
                  <Submit className="SubscriptionMergeApprovement__submit">
                    Oui, lancer la procédure de fusion de mes abonnements
                  </Submit>
                  <div className="SubscriptionMerge__cancel">
                    <Cancel onClick={onCancel}>
                      Non, utiliser un nouvel E-mail
                    </Cancel>
                  </div>
                </div>
              )}
              {successMessage && (
                <p className="SubscriptionMerge__success">{successMessage}</p>
              )}
            </form>
          </div>
          <div className="SubscriptionMerge__help">
            <p className="SubscriptionMerge__help__bold">
              Vous avez besoin d’aide pour vous connecter à votre espace client
              ?
            </p>
            <p className="SubscriptionMerge__help__text">
              Vous pouvez appeler le N° Cristal ci-dessous
            </p>
            <a href="tel:0970609955">
              <img
                src={numeroCristal}
                alt="numero cristal: 09 70 60 99 55"
                className="SubscriptionMerge__imgPhoneNumber"
              />
            </a>
          </div>
        </div>
      </div>
    </LayoutSubscription>
  );
}

export default withRouter(SubscriptionMerge);

import React, {useEffect} from 'react';
import LayoutConnected from '../../components/LayoutConnected/LayoutConnected';
import './PaymentRefused.scss';
import {Link} from "react-router-dom";

export default function PaymentRefused() {
  return (
    <LayoutConnected>
      <div className="PaymentRefused">
        <p className="PaymentRefused__text">
          Votre paiement a été refusé !<br/>
          <Link to="/home">Retour à la page d'accueil</Link>
        </p>
      </div>
    </LayoutConnected>
  );
}

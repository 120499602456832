import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, withRouter } from 'react-router-dom';
import { Submit, Input } from '../../components/Form';
import LayoutSubscription from '../../components/LayoutSubscription/LayoutSubscription';
import './UpdatePasswd.scss';

function UpdatePasswd(props) {
  const [successMessage, setSuccess] = useState('');
  const { register, handleSubmit, setError, errors } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {}, []);

  const onSubmit = (data) => {
    if (data.password === data['approve-password']) {
      fetch(`${process.env.REACT_APP_WEBSERVICES}/forgot_password`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          password: data.password,
          token: localStorage.getItem('new-password-token'),
        }),
      })
        .then((response) => {
          return response.text();
        })
        .then((response) => {
          if (response === '') {
            setSuccess(
              'Votre mot de passe a bien été modifié ! Retour sur la page de login pour saisir votre nouveau mot de passe'
            );
          } else {
            const datas = JSON.parse(response);
            if (datas.migrationToken) {
              localStorage.setItem(
                'email-approvement-token',
                datas.migrationToken
              );
              localStorage.setItem('email-approvement-password', data.password);
              props.history.push(`/validate-personal-infos`);
            }
          }
        });
    } else {
      setError('nomatch', {
        type: 'manual',
        message: 'Les mots de passe ne correspondent pas',
      });
    }
  };

  return (
    <LayoutSubscription>
      <div className="UpdatePasswd">
        <div className="UpdatePasswd__content">
          <h1 className="UpdatePasswd__title">
            Veuillez saisir votre nouveau mot de passe
          </h1>
          <div className="UpdatePasswd__connection">
            <form
              className="UpdatePasswd__form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Input
                type="password"
                label="Mot de passe"
                placeholder="Choisissez votre mot de passe"
                pattern="^(?=.*[A-Z])(?=.*\d)([-+!*$@%_\w]{8,})$"
                name="password"
                register={register({ required: true })}
                errors={errors}
              />
              <p className="ValidatePersonalInfos__input__help">
                Minimum 8 caractères, 1 majuscule, 1 numéro
              </p>
              <br />
              <Input
                type="password"
                label="Confirmez votre mot de passe"
                placeholder="Confirmez votre mot de passe"
                pattern="^(?=.*[A-Z])(?=.*\d)([-+!*$@%_\w]{8,})$"
                name="approve-password"
                register={register({ required: true })}
                errors={errors}
              />
              <Submit>Soumettre mon nouveau mot de passe</Submit>
            </form>
            {successMessage && (
              <p className="UpdatePasswd__success">{successMessage}</p>
            )}
            {errors.nomatch && (
              <p className="UpdatePasswd__error">{errors.nomatch.message}</p>
            )}
          </div>
        </div>
        <p className="UpdatePasswd__footer">
          <Link to="/login">Revenir à la page de connexion</Link>
        </p>
      </div>
    </LayoutSubscription>
  );
}

export default withRouter(UpdatePasswd);
